import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-4f6a5ff6"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "icon"
};
import { Toast } from 'vant';
import { ref } from "vue";
export default {
  __name: 'ShareComponent',

  setup(__props) {
    const showShare = ref(false);
    const options = [[{
      name: '微信',
      icon: 'wechat'
    }, {
      name: '朋友圈',
      icon: 'wechat-moments'
    }, {
      name: '微博',
      icon: 'weibo'
    }, {
      name: 'QQ',
      icon: 'qq'
    }], [{
      name: '复制链接',
      icon: 'link'
    }, {
      name: '分享海报',
      icon: 'poster'
    }, {
      name: '二维码',
      icon: 'qrcode'
    }, {
      name: '小程序码',
      icon: 'weapp-qrcode'
    }]];

    const onSelect = option => {
      Toast(option.name);
      showShare.value = false;
    };

    return (_ctx, _cache) => {
      const _component_van_icon = _resolveComponent("van-icon");

      const _component_van_share_sheet = _resolveComponent("van-share-sheet");

      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_van_icon, {
        name: "share-o",
        onClick: _cache[0] || (_cache[0] = $event => showShare.value = true)
      })]), _createVNode(_component_van_share_sheet, {
        show: showShare.value,
        "onUpdate:show": _cache[1] || (_cache[1] = $event => showShare.value = $event),
        title: "立即分享给好友",
        options: options,
        onSelect: onSelect
      }, null, 8, ["show"])], 64);
    };
  }

};