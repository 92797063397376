import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { setOsIp, setXToken } from "@/service/index";
import Home from "@/Home";
import NavHeader from "@/components/NavHeader";
import { useTitleExamStore } from "@/stores/titleExam";
import { useRoute } from "vue-router";
import { watch } from "vue";
import { getIps } from "@/service/apis/titleExam_api";
export default {
  __name: 'App',

  setup(__props) {
    const titleExamStore = useTitleExamStore();
    const route = useRoute(); //向请求头添加公共参数memberId

    watch(() => route.query.memberId, async value => {
      if (value) {
        await setXToken(value);
        titleExamStore.memberId = route.query.memberId;
        getIp();
      }
    });

    async function getIp() {
      const ua = navigator.userAgent.toLowerCase();

      const testUa = regexp => regexp.test(ua);

      let system = 'App';
      let os = undefined;

      if (testUa(/windows|win32|win64|wow32|wow64/ig)) {
        system = "pc"; // window系统
      } else if (testUa(/MicroMessenger/ig)) {
        system = 'mp'; //公众号 九宫格
      } else if (testUa(/miniProgram/ig)) {
        system = 'mina'; //小程序
      } else if (testUa(/AppleWebKit/ig)) {
        system = 'web';
      } else if (testUa(/macintosh|macintel/ig)) {
        system = "mac"; // macos系统
      } else if (testUa(/x11/ig)) {
        system = "linux"; // linux系统
      } else if (testUa(/android|adr/ig)) {
        system = "app_android"; // android系统
      } else if (testUa(/ios|iphone|ipad|ipod|iwatch/ig)) {
        system = "app_ios"; // ios系统
      }

      if (testUa(/android|adr/ig)) {
        os = 'android';
      } else if (testUa(/ios|iphone|ipad|ipod|iwatch/ig)) {
        os = 'ios';
      } else {
        os = undefined;
      }

      const ips = await getIps();

      if (!route.query.hasOwnProperty('channel')) {
        route.query.channel = 'app';
      }

      await setOsIp(route.query.channel, os, ips);
    }

    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");

      return _openBlock(), _createBlock(_component_router_view);
    };
  }

};