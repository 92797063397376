import {defineStore} from "pinia";

export const useNavHeaderStore = defineStore('navHeader', {
    state: () => {
        return {
            title:"职称考试",
            backRouter:'index',
            //展示方式 dropdown:下拉选择框,title:单个标题
            showType:"title",
            //下拉选择框的默认值
            dropdownValue:[],
            exeType:'exe',
            tileShowType:"title",
            //右侧展示方式 null:不展示,countdown:倒计时,share:分享按钮
            rightShowType:"countdown",
            //countdown倒计时开始时间 秒
            countdownTimes:4,
            //倒计时是否结束
            countdownIsOver:false,
            typeId:null

        }
    },
})
