import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
//导入Pinia
import { createPinia } from 'pinia'
//引入Vant

import { Toast,Notify,Col,Row,Popup,Button,NavBar,DropdownMenu, DropdownItem,Divider,Tab,Tabs,Checkbox,CheckboxGroup,Collapse,CollapseItem,ConfigProvider,Field,CellGroup,Lazyload} from 'vant';

//引入Vant css 按需引入
import 'vant/es/toast/style';
import 'vant/es/dialog/style';
import 'vant/es/tabs/style';
import 'vant/es/tab/style';
import 'vant/es/notify/style';
// import 'vant/es/image-preview/style';

    const app = createApp(App);
    //引入Pinia
    app.use(createPinia())
    //引入默认
    app.use(router).mount('#app')
    //引入vant所需要的组件

    app.use(Button)
        .use(NavBar)
        .use(DropdownMenu)
        .use(DropdownItem)
        .use(Divider)
        .use(Tab).use(Tabs)
        .use(Checkbox).use(CheckboxGroup)
        .use(ConfigProvider)
        .use(Field).use(CellGroup)
        .use(Popup)
        .use(Col)
        .use(Row)
        .use(Lazyload)
        .use(Notify)
        .use(Toast)
        .use(Collapse).use(CollapseItem);

