import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createSlots as _createSlots, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "navHeader"
};
import { onBeforeMount, onMounted, reactive, ref } from "vue";
import { useNavHeaderStore } from '@/stores/navHeader';
import { useTitleExamStore } from "@/stores/titleExam";
import ShareComponent from "@/components/ShareComponent";
import CountdownComponent from "@/components/CountdownComponent";
import { getExam, postExamIp } from "@/service/apis/titleExam_api"; //获取pinia数据

import { useRouter } from "vue-router";
export default {
  __name: 'NavHeader',

  setup(__props) {
    const router = useRouter();
    const navHeaderStore = useNavHeaderStore();
    const titleExamStore = useTitleExamStore();
    const eamClassifyValue = ref(0);
    const option1 = ref([]);
    navHeaderStore.$subscribe((arg, state) => {
      //需要写在数据变化前面
      option1.value = navHeaderStore.dropdownValue;
    }); //当下拉选择框改变的时候

    function change(value) {
      titleExamStore.$patch(state => {
        state.examClassifyValue = value;
        state.exeName = option1.value[value].text;
      });
      postExamIp('职称考试' + '-' + option1.value[value].text);
    }

    function onClickLeft() {
      if (titleExamStore.routerChange === 1) {
        router.push({
          path: "/myTest",
          query: {
            title: '我的考试',
            classId: titleExamStore.mockId
          }
        });
      } else if (titleExamStore.routerChange === 2) {
        router.push({
          path: "/titleExam",
          query: {
            memberId: titleExamStore.memberId
          }
        });
      } else {
        router.go(-1);
      }
    }

    return (_ctx, _cache) => {
      const _component_van_dropdown_item = _resolveComponent("van-dropdown-item");

      const _component_van_dropdown_menu = _resolveComponent("van-dropdown-menu");

      const _component_van_nav_bar = _resolveComponent("van-nav-bar");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_nav_bar, {
        title: _unref(navHeaderStore).title,
        "left-arrow": "",
        placeholder: true,
        fixed: true,
        "z-index": "3",
        onClickLeft: onClickLeft
      }, _createSlots({
        _: 2
      }, [_unref(navHeaderStore).tileShowType === 'dropdown' ? {
        name: "title",
        fn: _withCtx(() => [_createVNode(_component_van_dropdown_menu, null, {
          default: _withCtx(() => [_createVNode(_component_van_dropdown_item, {
            onChange: change,
            modelValue: eamClassifyValue.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => eamClassifyValue.value = $event),
            options: option1.value
          }, null, 8, ["modelValue", "options"])]),
          _: 1
        })])
      } : undefined, _unref(navHeaderStore).rightShowType !== 'null' ? {
        name: "right",
        fn: _withCtx(() => [_unref(navHeaderStore).rightShowType === 'countdown' ? (_openBlock(), _createBlock(_unref(CountdownComponent), {
          key: 0
        })) : _unref(navHeaderStore).rightShowType === 'share' ? (_openBlock(), _createBlock(_unref(ShareComponent), {
          key: 1
        })) : _createCommentVNode("", true)])
      } : undefined]), 1032, ["title"])]);
    };
  }

};