import {get, getIp, post, postIp, put} from '../index'
import qs from "qs";

/** 职称考试 **/

//添加埋点
export function postExamIp(name){
    return postIp('/v3/exe/exam/h5/report',name)
}


export function getIps(){
    return get('/v3/getIp')
}





//获取职称考试
export function getExam(exeName,fission){
    return getIp('/v3/exetypes/h5', exeName,{fission})
}
//获取同步练习列表
export function getTestExam(id,exeName){
    return getIp('/v3/exetypeh5s/'+id,exeName)
}
//获取同步练习题目
export function getTestTopic(id,exeName){
    return getIp('/v3/questions/Define/h5/'+id,exeName)
}
//获取随机题目
export function getRdndomTest(){
    return get('/v3/questions/h5')
}

//提交同步训练记录
export function postTestLog(data){
    return post('/v3/exe/log',data)
}

//是否加入我的错题
export function putExamAddWrong(exeId,questionId,data){
    return put('/v3/question/h5/cuo/exe/isAdd/'+exeId+'/'+questionId,qs.stringify(data))
}




export function postTestChange(data){
    return post('/v3/exe/isChange',qs.stringify(data))
}


export function postExamLog(data){
    return post('/v3/mock/h5/question/log',data)
}
//提交模拟考试总记录
export function postExamLog2(data){
    return post('/v3/mock/log',data)
}
//同步训练答题卡
export function getTestAnswer(id,randomOrDefine){
    return get('/v3/question/h5/answer/exe/' + id,{randomOrDefine})
}
//同步训练重做
export function putTestExam(exeId){
    return put('/v3/myExe/question/clean/' + exeId)
}
//模拟考试答题卡
export function getExamAnswer(id,randomOrDefine,uuId){
    return get('/v3/question/h5/answer/mock/' + id,{randomOrDefine,uuId})
}

//获取模拟自定义考试题目
export  function  getPractice(id,exeName){
    return get('/v3/questions/h5/mock/' + id,exeName)
}
//获取模拟随机考试题目
export function getRandomExam(mockId,exeName,exeOrMock,name,memberId){
    return getIp('/v3/questions/h5/random/' + mockId,exeName,{exeOrMock,name,memberId})
}
export function getRandomExam2(id,exeName){
    return getIp('/v3/questions/Random/h5/'+id,exeName)
}
