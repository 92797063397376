import {defineStore} from "pinia";

export const useTitleExamStore = defineStore('titleExam', {
    state: () => {
        return {
            //选择的考试类型值
            examClassifyValue:0,
            //name埋点
            exeName:'',
            exeTitle:'',
            exeSubTitle:'',
            memberId:null,
            exeChange:0,
            mockId:null,
            routerChange:0,
        }
    },
})
