
//测试--本地
// const api_header = "http://43.142.107.84:55555";
// const api_header = "http://172.20.10.6:55550";
// const api_header = "http://192.168.31.152:55554";
// const api_header = "http://yun-manage-api.yunicu.cn";
const api_header = "https://yun-new-admin-api.yunicu.com";
// const api_header = "https://49z442q252.picp.vip";
export default {
    api_header
}
