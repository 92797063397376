//封装的axios
import axios from 'axios'

import headerConfig from './api_header'
const header = headerConfig.api_header
let x_token;
//设置传值memberId

export async function  setXToken(token){
    x_token = token
}

let x_channel
let x_os
let x_ip
//log埋点  客服端，os，ip
export  function  setOsIp(channel,os,ip){
    x_channel=channel
    x_os=os
    x_ip=ip
}
function get(url,params){
    return  axios.get(header+url, {
        headers:{"memberId":x_token},
        params
    }).then(res => {
        return res.data
    }, err => {
        console.log(err);
    })
}
//log埋点
function getIp(url,exeName,params){
    return  axios.get(header+url, {
        headers:{"memberId":x_token,"channel":x_channel,"os":x_os,"ip":x_ip,"exeName":encodeURIComponent('职称考试-'+exeName),},
        params
    }).then(res => {
        return res.data
    }, err => {
        console.log(err);
    })
}


function post(url,params){
    return  axios.post(header+url,params,{
        headers:{"memberId":x_token}
    }).then(res => {
        return res.data;
    }, err => {
        console.log(err);
    })
}

//log埋点
function postIp(url,exeName,params){
    return  axios.post(header+url,params,{
        headers:{"memberId":x_token,"channel":x_channel,"os":x_os,"ip":x_ip,"exeName":encodeURIComponent(exeName)}
    }).then(res => {
        return res.data;
    }, err => {
        console.log(err);
    })
}
function put(url,params){
   return  axios.put(header+url,params,{
        headers:{"memberId":x_token}
    }).then(res => {
       return res.data;
   }, err => {
       console.log(err);
   })
}

function del(url,params){
    return  axios.delete(header+url,{
        headers:{"memberId":x_token},
        params
    }).then(res => {
        return res.data
    }, err => {
        console.log(err);
    })
}

export {get,post,put,postIp,getIp}

