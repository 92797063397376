import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../Home'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    children:[
//  我的考试
      {
        path:'/myTest',
        name:'MyTest',
        component:()=>import('@/views/MyTest'),
        meta: {
          title: "职称考试",
        },
      },
      //  我的考试详情
      {
        path:'/myTestParticulars',
        name:'MyTestParticulars',
        component:()=>import('@/views/MyTestParticulars'),
      },
      //  我的错题
      {
        path:'/wrongTopic',
        name:'WrongTopic',
        component:()=>import('@/views/WrongTopic'),
      },
      //  我的错题--同步练习详情
      {
        path:'/wrongPractice',
        name:'WrongPractice',
        component:()=>import('@/views/WrongPractice'),
      },
      //  我的错题--模拟考试详情
      {
        path:'/wrongDetailsExamination',
        name:'WrongDetailsExamination',
        component:()=>import('@/views/WrongDetailsExamination'),
      },
      //职称考试
      {
        path:'/titleExam',
        name:'TitleExam',
        component:()=>import('@/views/TitleExam'),
      },
      //同步练习列表
      {
        path:'/syncTestList',
        name:'SyncTestList',
        component:()=>import('@/views/SyncTestList'),
      },
      //同步练习答题
      {
        path:'/syncTest',
        name:'SyncTest',
        component:()=>import('@/views/SyncTest'),
      },
      //模拟考试答题
      {
        path:'/practiceTest',
        name:'PracticeTest',
        component:()=>import('@/views/PracticeTest'),
      },
    ]
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
