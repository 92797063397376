import { unref as _unref, toDisplayString as _toDisplayString } from "vue";
import { onBeforeUnmount, onMounted, ref } from "vue"; //获取pinia中的数据

import { useNavHeaderStore } from "@/stores/navHeader";
export default {
  __name: 'CountdownComponent',

  setup(__props) {
    let {
      countdownTimes
    } = useNavHeaderStore();
    const navHeaderStore = useNavHeaderStore();
    let time = ref(); //定义一个定时器

    let timer = null;
    onMounted(() => {
      handleCountDown(countdownTimes);
    });

    function handleCountDown(times) {
      handleTime(times);
      timer = setInterval(() => {
        const cutTime = --times;

        if (cutTime + 1 <= 0) {
          //状态改为已停止
          navHeaderStore.countdownIsOver = true; //清除计时器

          clearInterval(timer);
        } else {
          handleTime(cutTime);
        }
      }, 1000);
    }
    /**
     * 处理秒数，转换为时间单位
     * @param times
     */


    function handleTime(times) {
      //计算小时数 转化为整数
      let h = parseInt(String(times / 60 / 60 % 24)); //如果小时数小于 10，要变成 0 + 数字的形式 赋值给盒子

      let hour = h < 10 ? "0" + h : h; //计算分钟数 转化为整数

      let m = parseInt(String(times / 60 % 60)); //如果分钟数小于 10，要变成 0 + 数字的形式 赋值给盒子

      let minute = m < 10 ? "0" + m : m; //计算描述 转化为整数

      let s = parseInt(String(times % 60)); //如果秒钟数小于 10，要变成 0 + 数字的形式 赋值给盒子

      let second = s < 10 ? "0" + s : s;
      time.value = `${hour}:${minute}:${second}`;
    }
    /**
     * 监听countdownTimes倒计时时间的变化
     */


    useNavHeaderStore().$subscribe((mutation, state) => {
      if (mutation.events.key === 'countdownTimes') {
        //停止上一个计时器
        clearInterval(timer);
        timer = null;
        handleCountDown(mutation.events.newValue);
      }
    });
    onBeforeUnmount(() => {
      //页面关闭前停止定时器
      clearInterval(timer);
    });
    return (_ctx, _cache) => {
      return _toDisplayString(_unref(time));
    };
  }

};